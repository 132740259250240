<template>
  <a-form layout="horizontal">
    <a-form-item label="标题"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.title}}
    </a-form-item>
    <a-form-item label="发布人"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.publisher.name}}
    </a-form-item>
    <a-form-item label="发布时间"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.publishTime}}
    </a-form-item>
    <a-form-item label="内容"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.content}}
    </a-form-item>
    <a-form-item label="接收类型"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">
      <a-tag v-if="formItem.obj.receiveType==='all'" color="">全部</a-tag>
      <a-tag v-if="formItem.obj.receiveType==='dept'" color="green">部门</a-tag>
      <a-tag v-if="formItem.obj.receiveType==='user'" color="red">用户</a-tag>
    </a-form-item>
    <a-form-item label="接收对象"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.receiveNames}}
    </a-form-item>
    <a-form-item label="操作类型"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.systemModule}}
    </a-form-item>
    <a-form-item label="需要回复"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.ifReply?'是':'否'}}
    </a-form-item>
    <a-form-item label="期限天数"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.days}}天
    </a-form-item>
    <a-form-item label="截至接收日期"
                 :label-col="labelCol"
                 :wrapper-col="wrapperCol">{{formItem.obj.endTime}}
    </a-form-item>
  </a-form>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { superviseVO } from './common/superviseVO'

  export default {
    name: 'superviseDetail',
    components: {},
    data () {
      return {
        formItem: superviseVO(),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 }
      }
    },
    created () {
    },
    methods: {
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.platBase.supervise.view,
          params: {
            id: id
          },
          noTips: 'true',
          success: (data) => {
            Object.assign(this.formItem, data.body)
          }
        })
      }
    }
  }
</script>