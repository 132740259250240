<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-form-item label="标题"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input v-decorator="['title', {rules: [{ required: true, message: '请输入标题'}]}]"
                 placeholder="请输入标题"></a-input>
      </a-form-item>
      <a-form-item label="督办时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-date-picker format="YYYY-MM-DD" style="width: 100%"
                       @change="getPublishTime"
                       v-decorator="['publishTime', {rules: [{ required: true, message: '请选择督办时间'}]}]"
                       placeholder="请选择督办时间"></a-date-picker>
      </a-form-item>
      <a-form-item label="督办内容"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4"
                 v-decorator="['content', {rules: [{ required: true, message: '请输入督办内容'}]}]"
                 placeholder="请输入督办内容"/>
      </a-form-item>
      <a-form-item label="接收类型"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择接收类型" @change="receiveTypeChange"
                  v-decorator="['receiveType', {rules: [{ required: true, message: '请选择接收类型'}]}]">
          <a-select-option
            v-for="item in ReceiveTypeData"
            :key="item.label"
            :value="item.value"
            :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="接收对象" v-if="formItem.obj.receiveType!=='all'"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择接收对象" mode="multiple"
                  v-decorator="['receiveIds',]">
          <a-select-option
            v-for="item in receiveData"
            :key="item.id"
            :value="item.id"
            :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="操作类型"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择操作类型"
                  v-decorator="['systemModule', {rules: [{ required: true, message: '请选择操作类型'}]}]">
          <a-select-option
            v-for="item in SystemModuleData"
            :key="item.value"
            :value="item.value"
            :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="需要回复"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-radio-group :options="IfReply"
                       v-decorator="['ifReply',
                                     {rules:[{type:'string',required:true,message:'需要回复为必填项'}],
                                     trigger:'change',initialValue:'false'}]">
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="接收时间期限"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-form-item :style="{ display: 'inline-block', width: 'calc(30% - 50px)' }">
          <a-input v-decorator="['days', {rules: [{ required: true, message: '请输入时间期限'}]}]"
                   @change="getDays"
                   placeholder="请输入时间期限"></a-input>
        </a-form-item>
        <span :style="{ display: 'inline-block', width: '100px', textAlign: 'center' }">截至接收日期:</span>
        <a-form-item :style="{ display: 'inline-block', width: 'calc(70% - 50px)' }">
          <a-date-picker format="YYYY-MM-DD" style="width: 100%" disabled
                         v-decorator="['endTime', {rules: [{ required: true, message: '请选择截至接收日期'}]}]"
                         placeholder="截至接收日期"></a-date-picker>
        </a-form-item>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { superviseVO } from './common/superviseVO'
  import { ifReply, receiveTypeData, systemModuleData } from './common/common'
  import moment from 'moment'

  export default {
    name: 'superviseForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: {},
    data () {
      return {
        spinShow: false,
        ifSub: false,
        form: this.$form.createForm(this),
        formItem: superviseVO(),
        IfReply: ifReply(), //是否需要回复
        ReceiveTypeData: receiveTypeData(), //接收类型
        SystemModuleData: systemModuleData(), //操作类型
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        receiveData: [], //接收对象
        usersData: [] //当前单位下所有用户
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      },
      selfDepts () {
        return this.$store.getters.selfDepts
      }
    },
    mounted () {
    },
    created () {
      this.formItem.obj.publisher.id = this.currentUser.id
      this.loadUsersData()
    },
    methods: {
      //更新截至接收日期
      getPublishTime (e) {
        this.formItem.obj.publishTime = e
        this.formItem.obj.endTime = moment(this.formItem.obj.publishTime).add(this.formItem.obj.days, 'days')
        this.form.setFieldsValue({
          endTime: this.formItem.obj.endTime
        })
      },
      getDays (e) {
        this.formItem.obj.days = e.target.value
        this.formItem.obj.endTime = moment(this.formItem.obj.publishTime).add(this.formItem.obj.days, 'days')
        this.form.setFieldsValue({
          endTime: this.formItem.obj.endTime
        })
      },
      //接收类型改变
      receiveTypeChange (e) {
        this.formItem.obj.receiveType = e
        this.formItem.obj.receiveIds = []
        this.form.setFieldsValue({
          receiveIds: []
        })
        if (this.formItem.obj.receiveType === 'all') {
          this.receiveData = []
        } else if (this.formItem.obj.receiveType === 'dept') {
          this.receiveData = this.selfDepts
        } else {
          this.receiveData = this.usersData
        }
      },
      loadData (id) {
        //清空表单数据
        this.formItem = superviseVO()
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.platBase.supervise.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            //赋值接收对象
            this.receiveTypeChange(data.body.obj.receiveType)
            this.spinShow = false
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          title: this.formItem.obj.title,
          publishTime: moment(this.formItem.obj.publishTime),
          content: this.formItem.obj.content,
          receiveType: this.formItem.obj.receiveType,
          receiveIds: this.formItem.obj.receiveIds,
          receiveNames: this.formItem.obj.receiveNames,
          ifReply: this.formItem.obj.ifReply + '',
          systemModule: this.formItem.obj.systemModule,
          days: this.formItem.obj.days,
          endTime: moment(this.formItem.obj.endTime)
        })
      },
      setVOFields (values) {
        this.formItem.obj.title = values.title
        this.formItem.obj.publishTime = values.publishTime
        this.formItem.obj.content = values.content
        this.formItem.obj.receiveType = values.receiveType
        this.formItem.obj.receiveIds = values.receiveIds
        this.formItem.obj.receiveNames = values.receiveNames
        this.formItem.obj.ifReply = values.ifReply + ''
        this.formItem.obj.systemModule = values.systemModule
        this.formItem.obj.days = values.days
        this.formItem.obj.endTime = values.endTime
        this.formItem.fileIds = values.fileIds
      },
      submitAdd () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.platBase.supervise.create,
                data: this.formItem,
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  //清空表单数据
                  this.formItem = superviseVO()
                  this.$emit('addSuccess', data.body)
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          })
      },
      submitEdit () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.platBase.supervise.update,
                data: this.formItem,
                params: {
                  id: this.formItem.obj.id
                },
                success: (data) => {
                  //清空表单数据
                  this.formItem = superviseVO()
                  this.$emit('editSuccess', data.body)
                }
              })
            }
          }
        )
      },
      loadUsersData () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.usersData = data.body
          }
        })
      }
    }
  }
</script>